<template>
  <v-container class="bap">
  <v-row class="mt-10 action rounded">
    <!-- Bloc title -->
    <v-row class="mb-12 ml-5 mt-8 col-12">
      <v-col cols="12">
        <h1 class="mb-3">
          Calculez la surface de votre futur bureau
        </h1>
        <p class="subheading font-weight-regular mt-5">
          Adaptez la surface de votre bureau en fonction de votre équipe.
        </p>
      </v-col>
    </v-row>

  <!-- Bloc Recherche -->
  <v-row class="ml-1 mb-5 mr-1" align="start">
    <v-col class="col-md-8" cols="12">
      <v-row align="start">
        <v-col class="" cols="12">
          <v-card 
            elevation="2" 
            class=""
          >
          <v-row>
            <v-col class="col-lg-6 pa-8" cols="12">
                <h4 class="d-flex mb-5">Votre équipe</h4>
                <span class="ml-1" v-if="value_nb_employes"><strong>Nombre de collaborateurs actuel</strong></span>
                <v-text-field
                  v-model="recherche.nombre_employes_actuel.value"
                  class="input-custom"
                  outlined
                  dense
                  color="black"
                  placeholder="0"
                  type="number"
                  style=""
                  :suffix="suffix('collaborateur', recherche.nombre_employes_actuel.value)"
                  @change="affiche_label('nb_employes')"
                ></v-text-field>
                <span class="ml-1" v-if="value_recrutements"><strong>Recrutements prévus cette l'année</strong></span>
                <v-text-field
                  v-model="recherche.recrutements_prevus.value"
                  class="input-custom"
                  outlined
                  dense
                  color="black"
                  placeholder="0"
                  type="number"
                  :suffix="suffix('collaborateur', recherche.recrutements_prevus.value)"
                  style=""
                  @change="affiche_label('recrutements')"
                ></v-text-field>
                <span class=""><strong>Télétravail (jours / semaine)</strong></span>
                  <v-slider
                    v-model="recherche.teletravail.value"
                    :max=recherche.teletravail.max
                    :min=recherche.teletravail.min
                    :step=recherche.teletravail.step
                    :tick-labels=recherche.teletravail.values
                    color="#3C46DC"
                    tick-size="0"
                    track-color="gray"
                    style=""
                    class="mx-n1"
                  >
                  </v-slider>
            </v-col>
            <v-col class="col-lg-6 pa-8" style="border-left: 1px solid lightgrey" cols="12">
                <h4 class="d-flex mb-5">Vos besoins</h4>
                <span class="d-flex mt-5" v-if="value_salles_reunion"><strong>Nombre de salles de réunion</strong></span>
                <v-text-field
                  v-model="recherche.salles_reunion.value"
                  class="input-custom"
                  outlined
                  dense
                  color="black"
                  type="number"
                  style=""
                  placeholder="0"
                  :suffix="suffix('salle', recherche.salles_reunion.value)"
                  @change="affiche_label('salles_reunion')"
                ></v-text-field>
                <span class="d-flex mt-5"><strong>Types de postes</strong></span>
                <v-slider
                  v-model="recherche.type_postes.value"
                  :max=recherche.type_postes.max
                  :min=recherche.type_postes.min
                  :step=recherche.type_postes.step
                  :tick-labels="recherche.type_postes.values"
                  color="#3C46DC"
                  track-color="gray"
                  class="mx-n2"
                  tick-size="0"
                >
                </v-slider>
                <span class="d-flex mt-5"><strong>Espaces de vie et de détente</strong></span>
                <v-slider
                  v-model="recherche.detente.value"
                  :max=recherche.detente.max
                  :min=recherche.detente.min
                  :step=recherche.detente.step
                  :tick-labels="recherche.detente.values"
                  color="#3C46DC"
                  track-color="gray"
                  class="mx-n2"
                  tick-size="0"
                >
                </v-slider>
                <span class="d-flex mt-5"><strong>Taille de la cuisine</strong></span>
                <v-slider
                  v-model="recherche.cuisine.value"
                  :max=recherche.cuisine.max
                  :min=recherche.cuisine.min
                  :step=recherche.cuisine.step
                  :tick-labels="recherche.cuisine.values"
                  color="#3C46DC"
                  track-color="gray"
                  class="mx-n2"
                  tick-size="0"
                >
                </v-slider>
            </v-col>
          </v-row>
         </v-card>
        </v-col>
      </v-row>
    </v-col>

    <!-- Arrow down -->
    <v-col class="col-md-1 d-sm-flex d-md-none d-lg-none" cols="12">
      <v-container fluid>
        <v-row align="center" justify="center" class="ml-n8 mt-n8">
          <v-col>
            <div class="text-center">
              <ph-caret-down :size="48" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-col>

  <!-- Résultat -->
    <v-col class="col-md-4 ml-md-0" cols="12">
      <v-container fluid>
        <v-row align="center" justify="center" class="mt-0" no-gutters>
          <v-col class="d-none d-md-flex mr-md-0" cols="2">
            <ph-caret-right :size="32" />
          </v-col>
          <v-col class="col-md-10" cols="12">
            <v-card
              elevation="2"
              class="mr-0 px-0 pb-0"
            >
            <v-card
              class="mr-0 px-0 pb-0"
              flat
            >
              <div class="text-center">
                <v-btn
                  color="#3C46DC"
                  depressed
                  large
                  rounded
                  :ripple="false"
                  class="floatin pa-6"
                  id="no-background-hover-1"
                >
                  <span class="button-contract">Votre bureau idéal</span>
                </v-btn>
              </div>

              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="text-center mb-3">Surface totale nécessaire</v-list-item-subtitle>
                  <v-list-item-title class="mb-4">
                    <h3 class="mt-1 text-center" v-html="surface_totale(true)" />
                    <div class="mt-12 text-center">
                      <a 
                        :href="link_recherche()" 
                        style="color:#3C46DC"
                      ><strong>Voir les offres disponibles</strong></a>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card
              class="mr-0 px-0 pb-0"
              style="background-color:#F9F9FA;"
              flat
            >
              <v-divider></v-divider>
              <v-row>
                <v-col class="col-md-12 col-sm-12" cols="12">
                  <v-row class="pa-4" >
                    <v-col class="col-8">
                      Nombre de postes
                    </v-col>
                    <v-col class="text-right col-4">
                      <span v-html="nombre_poste()"></span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider></v-divider>
                <v-col class="col-md-12 col-sm-12" cols="12">
                  <v-row class="pa-4">
                    <v-col class="col-8">
                      Salles de réunion
                    </v-col>
                    <v-col class="text-right col-4">
                      <span v-html="surface_reunions(true)"></span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider></v-divider>
                <v-col class="col-md-12 col-sm-12" cols="12">
                  <v-row class="pa-4">
                    <v-col class="col-8">
                      Espaces de vie
                    </v-col>
                    <v-col class="text-right col-4">
                      <span v-html="surface_espaces_vie(true)"></span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-col>

  </v-row>
</v-row>

<!-- Call to action -->
  <v-row class="mt-7 cta-block align-center rounded pa-3 pb-5 mb-2">
    <v-col class="col-md-3 col-lg-2" cols="12">
      <div class="ml-5 mt-3 mt-md-1">
        <img src="../assets/portrait-equipe.png" style="height:60px">
      </div>
    </v-col>
    <v-col class="col-md-7" cols="12">
      <div class="ml-5 mb-0 mt-md-5">
        <p class="mb-5 mb-md-0">
          Vous cherchez la configuration de bureaux idéale pour votre équipe ?
        </p>
        <p class="">
          <a href="https://www.ubiq.fr/solutions" style=""><strong>Contactez nos experts <span class="ml-1"><PhArrowRight /></span></strong></a>
        </p>
      </div>
    </v-col>
  </v-row>


</v-container>
</template>

<style lang="scss">

@font-face {
  font-family: "RoobertRegular";
  src: local("RoobertRegular"),
   url('../assets/Roobert-Regular.woff') format("woff");
}
@font-face {
  font-family: "RoobertSemiBold";
  src: local("RoobertSemiBold"),
   url('../assets/Roobert-SemiBold.woff') format("woff");
}
$font-family-regular: 'RoobertRegular';
$font-family-bold: 'RoobertSemiBold';
$body-font-family: 'Roobert' !important;

.bap {
  [class*='template-'],
  [class*='table-'],
  [class*='td-'],
  [class*='v-data'],
  [class*='v-application'],
  [class*='subtitle-'],
  [class*='text-'] {
    font-family: $font-family-regular, sans-serif !important;
  }
  font-family: $font-family-regular, sans-serif !important;
}

@media (min-width: 960px) {
  .action {
    background-color:#E8EEFC;
    background-image: url('../assets/workplace_400.png');
    background-position: right top;
  }
}
@media (max-width: 960px) {
  .action {
    background-color:#E8EEFC;
  }
}
@media (max-width: 1264px) {
  .v-slider {
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  .v-slider {
    font-size: 11px;
  }
}

#no-background-hover-1::before {
   background-color: #3C46DC !important;
}
#no-background-hover-1:hover {
   background-color: #3C46DC !important;
}

.h5 {
  font-family: $font-family-regular, sans-serif !important;

}

div.v-slider__thumb-label {
  left:6px !important;
  top:px !important;
}

div.v-slider__thumb {
    width: 24px;
    height: 24px;
    left: -12px
}

div.v-slider__thumb__active {
    width: 24px;
    height: 24px;
}

.v-slider__thumb:before {
    left:-6px !important;
    top:-6px !important;
}

.v-slider--horizontal .v-slider__track-container {
    height: 4px !important;
}

.v-slider--vertical .v-slider__track-container {
    width: 4px !important;
}


.v-slider__tick-label {
  top:12px !important;
  left:0px !important;
  // margin-left: 0px !important;
  // width: 33% !important;
}

.v-slider__tick {
  margin-left: 0px !important;
}

.caretright {
  position: absolute;
  right: -40px;
  top:50%;
}

.input-custom {
  font-family: "RoobertRegular";
  font-size: 16px;
  width:100%;
  // border: 1px solid #DBDBDB !important;
}

.bloc_haut {
  border: 1px solid;
}

.recherche {
  border: 1px white solid;
  background-color: white;
  border-radius: 5px;
}

h1 { 
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    font-family: $font-family-bold;
    color: black;
    text-align: left!important;
}

h4 { 
    font-weight: 600;
    font-size: 1.500rem;
    line-height: 1.5rem;

    // font-size: 24px;
    // font-weight: 600;
    // line-height: 32px;
    font-family: $font-family-bold;
    color: black;
    text-align: left!important;
    letter-spacing: 1px;
}

h3 { 
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    font-family: $font-family-bold;
    color: black;
    text-align: left!important;
    letter-spacing: 1px;
}

.v-list .v-list-item--active { 
  background-color: #3C46DC!important; 
}
.v-list .v-list-item--active .v-list-item__title {
  color: white !important;
}
.text-caption {
    font-size: 12px;
    font-weight: 400;
    font-family: $font-family-regular;
    color: black;
    text-align: left!important;
}

p {
    font-size: 16px;
    font-weight: 400;
    font-family: $font-family-regular;
    color: black;
    text-align: left!important;
}

.floatin {
  top:-20px;
}

.v-btn.floatin {
  cursor: default;
}
.v-btn.floatin:before {
    background-color: transparent;
}
.v-btn.floatin span:hover{
    background-color: #3C46DC;
  }
.button-contract {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin: 12px;
  text-transform: none;
  color: white;
  font-family: $font-family-regular;
}

.environ {
    font-size: 12px;
    font-weight: 200;
    font-family: $font-family-regular;
    color: #636363;
    text-align: left!important;
}
.cta-block {
  background-color: #FFEEE5;
}

.button { 
    font-size: 16px;
    font-weight: 600;
    font-family: $font-family-bold;
    color: black;
}

.edito {
  background-color: #F9F9FA;
  border-top: lightgray solid 1px;
  vertical-align: baseline;
  height: 150px;
}

.plus {
  color:#00990F;
  font-size: 14px;
  font-weight: 600;
}

.minus {
  color:#F03800;
  font-size: 14px;
  font-weight: 600;
}

</style>


<script>
import store from '../store'

// import { PhClipboardText, PhArrowFatLinesRight, PhCaretDown } from "phosphor-vue";
import { PhCaretDown, PhArrowRight, PhCaretRight } from "phosphor-vue"


  export default {
    name: 'CalcSurface',
    store,
    components: {
      // PhCaretRight,
      PhArrowRight,
      PhCaretDown,
      PhCaretRight,
      // PhMinus,
      // PhPlus,
    },
    data: () => ({
      value_nb_employes: true,
      value_recrutements: true,
      value_salles_reunion: true,
      value_grandes_salles: true,
      recherche: {
        teletravail: {
          max: 5,
          min: 0,
          step: 1,
          value: 2,
          values: ["0",1,2,3,4,5]
        },
        nombre_employes_actuel: {
          value: "",
        },
        recrutements_prevus: {
          value: "",
        },
        salles_reunion: {
          value: "",
          reference: 14
        },
        type_postes: {
          max: 2,
          min: 0,
          step: 1,
          value: 1,
          values: [
            "Open space",
            "Mix des deux",
            "Cloisonnés"
          ],
          reference: [
            6,
            8,
            10
          ]
        },
        detente: {
          max: 2,
          min: 0,
          step: 1,
          value: 1,
          values: [
            "Un peu",
            "Moyen",
            "Beaucoup"
          ],
          reference: [
            1,
            1.5,
            2
          ]
        },
        cuisine: {
          max: 2,
          min: 0,
          step: 1,
          value: 1,
          values: [
            "Petite",
            "Moyenne",
            "Grande"
          ],
          reference: [
            8,
            15,
            25
          ],
        },
      },

    }),
    methods: {
      suffix: function(name, value) {
        if (value > 1) {
          return name + 's'
        } else {
          return name
        }
      },
      affiche_label: function (textFieldName){
        eval('this.value_'+textFieldName+' = true')
      },
      link_recherche: function (){
        if (this.surface_totale(true) !== "-"){
          let surface = this.surface_totale(false)
          let surface_min = parseInt(surface*0.9)
          let surface_max = parseInt(surface*1.1)
          console.log(surface, surface_min, surface_max)
          return "https://www.ubiq.fr/recherche?surface-min="+surface_min+"&surface-max="+surface_max
        } else {
          return 'https://www.ubiq.fr/recherche'
        }
      },
      nombre_poste: function () {
        let nb = 0
        nb = (parseInt(this.recherche.nombre_employes_actuel.value)+parseInt(this.recherche.recrutements_prevus.value))*(1-this.recherche.teletravail.value/5)
        if (isNaN(nb)) {
          return "-"
        }
          return Math.round((parseInt(this.recherche.nombre_employes_actuel.value)+parseInt(this.recherche.recrutements_prevus.value))*(1-this.recherche.teletravail.value/5))
      },
      surface_totale: function (format=false) {
        let st = 0
        let my = this.recherche
        st = this.nombre_poste()*(my.type_postes.reference[my.type_postes.value]+my.detente.reference[my.detente.value])
        st = st + (my.cuisine.reference[my.cuisine.value])
        st = st + (my.salles_reunion.value*my.salles_reunion.reference)
        if (isNaN(st)) {
          return "-"
        }
        if (format) {
          return Math.round(st)+" m<sup>2</sup>"
        } else {
          return Math.round(st)
        }
      },
      surface_reunions: function (format=false) {
        let sr = 0
        let my = this.recherche
        sr = my.salles_reunion.value*my.salles_reunion.reference
        if (isNaN(sr) || sr === 0) {
          return "-"
        }
        if (format) {
          return Math.round(sr)+" m<sup>2</sup>"
        } else {
          return Math.round(sr)
        }
      },
      surface_espaces_vie: function (format=false) {
        let sev = 0
        let my = this.recherche
        sev = (this.nombre_poste()*my.detente.reference[my.detente.value])+(my.cuisine.reference[my.cuisine.value])
        if (isNaN(sev)) {
          return "-"
        }
        if (format) {
          return Math.round(sev)+" m<sup>2</sup>"
        } else {
          return Math.round(sev)
        }
      },
      fmtNumber: function (number) {
          if (typeof number == 'number') {
              return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(Math.round(number))
          }
      },
    },
    computed: {
      
    }

  }
</script>
