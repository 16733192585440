import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sortieCash369: 115662,
    coutMensuel369: 13424,
    coutM2CC: 921,
  },
  mutations: {
    updateSortieCash369(state, value) {
      state.sortieCash369 = value
    },
    updateCoutMensuel369(state, value) {
      state.coutMensuel369 = value
    },
    updateCoutM2CC369(state, value) {
      state.coutM2CC369 = value
    },
    updateCoutAnneeUn369(state, value) {
      state.coutAnneeUn369 = value
    },
    updateSortieCashService(state, value) {
      state.sortieCashService = value
    },
    updateCoutMensuelService(state, value) {
      state.coutMensuelService = value
    },
    updateCoutAnneeUnService(state, value) {
      state.coutAnneeUnService = value
    },
    updateCoutM2CCService(state, value) {
      state.coutM2CCService = value
    },
    updateSortieCashLocation(state, value) {
      state.sortieCashLocation = value
    },
    updateCoutMensuelLocation(state, value) {
      state.coutMensuelLocation = value
    },
    updateCoutM2CCLocation(state, value) {
      state.coutM2CCLocation = value
    },
  },
  actions: {
  },
  modules: {
  }
})
